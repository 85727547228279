import React, { memo } from "react";
import PropTypes from "prop-types";

import "../styles/music.scss";

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        loading = "lazy"
      />
    </div>
  );
  
  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };

const Music = memo(({ refs }) => {
  return (
    <section ref={refs} className="portfolio-section">
      <div className="section-title">MUSIC</div>
      <div className="portfolio-content">
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
                <YoutubeEmbed embedId="ZC1cebFpWh4"></YoutubeEmbed>
            </div>
              <br />
            <div className="title">Black Key Etude</div>
          </div>
        </div>

        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
                <YoutubeEmbed embedId="hjyculA1aXw"></YoutubeEmbed>
            </div>
              <br />
            <div className="title">Jacobson Recital</div>
          </div>
        </div>
        
        
      </div>
    </section>
  );
});

export default Music;