import React, { useCallback, useState, memo } from "react";
import "../styles/navbar.scss";
import Pdf from './Resume.pdf';


import { MdMenu } from "react-icons/md";

const Navbar = memo(({ handleScroll }) => {
  const [active, setActive] = useState(false);

  const click = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <>
      <header>
        <nav className="navbar">
          <div className="navbar__logo">Timothy Fong</div>
          
          <ul className="navbar__menu">
            <li>
              <button onClick={() => handleScroll("about")}>About me</button>
            </li>
            <li>
              <button onClick={() => handleScroll("portfolio")}>
                Portfolio
              </button>
            </li>
            <li>
              <button onClick={() => handleScroll("skills")}>Skills</button>
            </li>
            <li>
              <button onClick={() => handleScroll("music")}>
                Music
              </button>
            </li>
            {/* <li>
            <button>
              <a href = {Pdf} target="_blank">Resume</a>
            </button>
            </li>reirhehre */}
            <li>
            <button>
              <a href = "https://github.com/Timfon" target="_blank">Github</a>
            </button>
            </li>

          </ul>

          <div className="toggleBtn" onClick={click}>
            <MdMenu />
          </div>
        </nav>
      </header>
      {active && (
        <div className="toggle__menu">
          <ul>
            <li>
              <button
                onClick={() => {
                  handleScroll("about");
                  click();
                }}
              >
                About me
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleScroll("skills");
                  click();
                }}
              >
                Skills
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleScroll("portfolio");
                  click();
                }}
              >
                Portfolio
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleScroll("music");
                  click();
                }}
              >
                Music
              </button>
            </li>

          </ul>
        </div>
      )}
    </>
  );
});

export default Navbar;
